import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { spAvatar } from "./assets";

export const configJSON = require("./config");
interface Vehicle {
  vehicleVin: string,
  vehicleType: string,
  vehicleYear: string,
  vehicleWeightEmpty: string,
  vehicleWeightGross: string,
  make: string,
  odometer: string,
  bodyType: string,
}

export interface ServiceProvider{
  id: string,
  type: string,
  selectedService: string,
  createdAt: string,
  updatedAt: string,
  serviceProviderName: string | undefined,
  serviceProviderLogo: string | undefined,
  hiredOn: string,
}

export interface Service{
  id: string,
  name: string,
  positivePercent: number,
  service: string,
  status: string,
  logo: string,
}

export interface User{
  id: string;
  avatar: string;
  role: string;
}

export interface Document{
  id: string;
  filename: string;
  url: string;
  size?: number;
  type?: string;
  status?: string;
  issueRaised?: boolean;
}

export interface DealInfo {
  dealId: string,
  fromState: string,
  toState: string,
  applicationType: string,
  registrationType: string,
  vehicle: Vehicle,
  saleType: string,
  soldAs: string,
  msrp: string,
  tsp: string,
  sellerZip: string,
  purchaserZip: string,
  ownerFirstName: string,
  ownerLastname: string,
  firstOwner: string,
  secondOwner: string,
  dealershipName: string,
  dealDocuments: Document[],
  dateOfSale: string,
  dealStatus: string
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStatus: string,
  dealInfo: DealInfo,
  user: User,
  sidebar: boolean,
  hiredSPInfo: ServiceProvider,
  sentServiceProviders: Service[],
  isLoading: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDealDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDealInformationId: string = '';
  apiGetUserInformationId: string = '';
  apiGetAcceptedSP: string = '';
  apiSendFeeToSPId: string = '';
  apiGetSentServices: string = '';
  apiUpdateDealStatus: string = '';

  inProgress = 'In Progress';
  issuesSentToDealer = 'Issues Sent to Dealer';
  resolvedInDealerReview = 'Resolved, In Dealer Review';
  completed = 'Completed';
  dealerFlagsIncomplete = 'Dealer Flags Incomplete';
  deleted = 'Deleted';

  handleChangeSelectedStatus = (newStatus: string)=> {
    this.setState({selectedStatus: newStatus});
    const updatedStatus = newStatus.toLowerCase().replace(/,/g, '').replace(/\s+/g, '_'); 
    this.updateDealStatus(updatedStatus);
  }

  updateDealStatus = (newStatus: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateDealStatus = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/update_deal_status/' + this.state.dealInfo.dealId + '?status=' + newStatus
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  navigateToOrderSummary = () => {
    this.navigateToScreen('OrderSummary');
  }

  navigateToOrderSummaryBlankFormFee = () => {
    this.navigateToScreen('OrderSummaryBlankFormFee');
  }
  
  navigateToOrderSummaryChecklistFee = () => {
    this.navigateToScreen('OrderSummaryChecklistFee');
  }

  navigateToOrderSummaryCalculateRegistrationFee = () => {
    this.navigateToScreen('OrderSummaryCalculateRegistrationFee');
  }
  
  navigateToServiceProviderDashboards = () => {
    localStorage.setItem('state_code', this.state.dealInfo.toState);
    this.navigateToScreen('ServiceProviderDashboard');
  }

  getSentServices = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSentServices = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/select_service_providers/get_hired_providers?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  getAcceptedServiceProvider = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAcceptedSP = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/fetch_accepted_services?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getDealInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/get_user'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const vehicle : Vehicle = {
      vehicleVin : response.vehicle_vin,
      vehicleType: response.vehicle_type,
      vehicleYear: response.vehicle_year,
      vehicleWeightEmpty: response.vehicle_weight_empty,
      vehicleWeightGross: response.vehicle_weight_gross,
      make: response.make,
      odometer: response.odometer,
      bodyType: response.body_type
    }

    const dealInfo : DealInfo = {
      dealId: response.id,
      ownerFirstName: response.owner_first_name,
      ownerLastname: response.owner_last_name,
      fromState: response.from_state,
      toState: response.to_state,
      applicationType: response.application_type,
      registrationType: response.registration_type,
      saleType: response.sale_type,
      soldAs: response.sold_as,
      msrp: response.msrp,
      tsp: response.tsp,
      sellerZip: response.seller_zip,
      purchaserZip: response.purchaser_zip,
      firstOwner: response.first_owner,
      secondOwner: response.second_owner,
      vehicle: vehicle,
      dealershipName: response.dealership_detail.data.attributes.dealership_name,
      dealDocuments: response.deal_documents.map((doc: any) => ({id: doc.id, filename: doc.filename, url: doc.url, status: doc.status, issueRaised: doc.issue_raised})),
      dateOfSale: response.date_created,
      dealStatus: response.deal_status
    }

    return dealInfo;
  }

  closeDrawerHandler = () =>{
    this.setState({sidebar: false});
  }

  mapToHiredSPInfo = (response: any) => {
    if (response.data) {
      const {id, type} = response.data[0];
      const {selected_services, created_at, updated_at, service_provider_name, service_provider_logo, hired_on} = response.data[0].attributes;
      return{
        id,
        type,
        selectedService: selected_services,
        createdAt: created_at,
        updatedAt: updated_at,
        serviceProviderName: service_provider_name || 'Blue Tree Services',
        serviceProviderLogo: service_provider_logo || spAvatar,
        hiredOn: hired_on,
      }
    } else {
      return {} as ServiceProvider;
    }
  }

  mapToSentService = (response: any) => {
    let sentServices: Service[]= [];
    if (response.data) {
      response.data.forEach((data: any) => {
        const {id} = data;
        const {status, selected_services, service_provider_name, service_provider_logo, positive_like_percentage} = data.attributes;
        sentServices.push({
          id: id,
          status: status,
          service: selected_services,
          logo: service_provider_logo,
          name: service_provider_name,
          positivePercent: positive_like_percentage
        })
      })
    }
    return sentServices;
  }

  sendToServiceProvider = (fee: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendFeeToSPId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_tasks/tasks'
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            fee_type : "manual",
            fee_amount: fee,
            deal_id: localStorage.getItem("createdDealId")
          }
        },
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.state = {
      selectedStatus: "In Progress",
      dealInfo: {} as DealInfo,
      user: {} as User,
      sidebar: false,
      hiredSPInfo: {} as ServiceProvider,
      sentServiceProviders: [],
      isLoading: true,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDealInformation();
    this.getUserInformation();
    this.getSentServices();
    this.getAcceptedServiceProvider();
    const url = new URL(window.location.toString());
    const sidebar = url.searchParams.get("sidebar");
    if(sidebar){
      this.setState({sidebar: sidebar === "true"});
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(this.state.dealInfo !== prevState.dealInfo && this.state.dealInfo !==  {} as DealInfo){
        this.setState({
          isLoading: false
        })
      }
  }

  getDealStatus = (status: string)=>{
    switch(status){
      case 'in_progress': 
        return this.inProgress;
      case 'issue_sent_to_dealer':
        return this.issuesSentToDealer;
      case 'resolved_in_dealer_review':
        return this.resolvedInDealerReview;
      case 'completed':
        return this.completed;
      case 'dealer_flags_incomplete':
        return this.dealerFlagsIncomplete;
      case 'deleted':
        return this.deleted;
    }
    return '';
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        switch(apiId){
          case this.apiGetDealInformationId:
            this.setState({
              dealInfo: this.mapToDealInfo(response.data.attributes),
              selectedStatus: this.getDealStatus(response.data.attributes.deal_status)
            });
            break;
          case this.apiGetUserInformationId:
            this.setState({
              user: {id: response.data.id, avatar: response.data.attributes.profile_picture, role: response.data.attributes.role}
            })
            break;
          case this.apiGetAcceptedSP:
            this.setState({
              hiredSPInfo: this.mapToHiredSPInfo(response),
            })
            break;
          case this.apiGetSentServices:
            this.setState({
              sentServiceProviders: this.mapToSentService(response),
            })
            break;
          case this.apiUpdateDealStatus:
            break;
        }
      }
    }
    // Customizable Area End
  }
}